//
// Override global variables
//

// Theme colors

$primary:       									#0BB783;
$primary-hover:    									#04AA77;
$primary-light:    									#D7F9EF;
$primary-inverse:  									#FFFFFF;


// Secondary colors
$secondary:     									#B5B5C3;
