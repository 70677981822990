.dd-menu{ 
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 96;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #3F4254;
    text-align: left;
    list-style: none;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 0 solid rgba(0, 0, 0, 0.15);
    border-radius: 0.42rem;
    box-shadow: 0px 0px 50px 0px rgb(82 63 105 / 15%);

    &[dt-lvl="2"]{
        top: 0;
        left: calc(100% + 6px);
    }

    .dd-item{
        outline: none !important;
        display: flex;
        flex-grow: 1;  
        width: 100%;
        padding: 0.75rem 1.25rem;
        clear: both;
        font-weight: 400;
        color: #181C32;
        text-align: inherit;
        white-space: nowrap;
        background-color: transparent;
        border: 0;

        &:hover, 
        &:focus {
            color: #101221;
            text-decoration: none;
            background-color: #EBEDF3;
            cursor: pointer;
        }

        &:active {
            color: #ffffff;
            text-decoration: none;
            background-color: #0BB783;
            cursor: pointer;
        }

        &.dd-link{
            padding: 0px;
        }

        .dd-btn{
            padding: 0.75rem 1.25rem;
            width: 100%;
            color: inherit;

            &:hover, 
            &:focus {
                color: #101221;
                text-decoration: none;
                background-color: #EBEDF3;
                cursor: pointer;
            }

            &:active {
                color: #ffffff;
                text-decoration: none;
                background-color: #0BB783;
                cursor: pointer;
            }
        }
    }

    .dd-custom-el{
        padding: 0.75rem 1.25rem;
    }
    
}